@mixin focus-visible {
  &:focus-visible {
    @content;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      @content;
    }
  }
}
