@use 'sass:math';

@mixin aspect-ratio($width: null, $height: null, $ar: null) {
  @if not $ar {
    @if not $width or not $height {
      @error 'You must provide either a width and height or an aspect ratio.';
    }

    $ar: math.div($width, $height);
  } @else {
    @if $width or $height {
      @error 'You must provide either a width and height or an aspect ratio, but not both.';
    }
  }

  aspect-ratio: $ar;

  // Fallback for browsers that don't support aspect-ratio, like Safari <= 14.
  // Based on this answer: https://stackoverflow.com/a/72390001/
  @supports not (aspect-ratio: $ar) {
    &::before {
      float: left;
      padding-top: calc((1 / $ar) * 100%);
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
}
