@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/focus-visible';

.wrapper {
  --logo-width: 48px;
  --logo-top: 12px;
  --big-text-size: #{rem-calc(45)};
  --small-text-size: #{rem-calc(19)};
  --boxes-width: 70%;
  --falling-kids-height: 60vw;

  --kid-one-width: 27%;
  --kid-one-left: 50%;

  --kid-two-width: 20%;
  --kid-two-left: 21%;

  --kid-three-width: 31%;
  --kid-three-left: 45%;

  display: grid;
  grid-gap: var(--pbsk-spacing-4);
  grid-template-areas:
    'fallingkids'
    'whoopstext'
    'boxes';
  grid-template-rows: var(--falling-kids-height) auto auto;
  padding-top: var(--pbsk-spacing-4);

  @include breakpoint(xs) {
    --big-text-size: #{rem-calc(70)};
    --small-text-size: #{rem-calc(21)};
  }

  @include breakpoint(sm) {
    --boxes-width: 100%;
  }

  @include breakpoint(md) {
    --logo-width: 100px;
    --logo-top: 18px;
    --big-text-size: #{rem-calc(63)};
    --small-text-size: #{rem-calc(18)};
    --falling-kids-height: 42vw;

    --kid-one-width: 47%;
    --kid-one-left: 47%;

    --kid-two-width: 34%;
    --kid-two-left: 5px;

    --kid-three-width: 62%;
    --kid-three-left: 39%;

    grid-template-areas:
      'fallingkids whoopstext'
      'fallingkids boxes';
    grid-template-columns: 32vw 1fr;
    grid-template-rows: 2fr 1fr;
    min-height: var(--falling-kids-height);
  }

  @include breakpoint(lg) {
    --big-text-size: #{rem-calc(80)};
    --small-text-size: #{rem-calc(21)};
    --falling-kids-height: 415px;

    grid-column-gap: var(--pbsk-spacing-8);
    grid-template-columns: 345px 1fr;
  }

  @include breakpoint(xl) {
    --logo-width: 150px;

    grid-template-areas:
      'div fallingkids whoopstext'
      'div fallingkids boxes';
    grid-row-gap: var(--pbsk-spacing-8);
    grid-template-columns: 120px 345px 1fr;
  }

  @include breakpoint(xxl) {
    --boxes-width: 750px;

    grid-column-gap: var(--pbsk-spacing-15);
  }
}

.kidsLogo {
  display: block;
  position: absolute;
  z-index: 1;
  top: var(--logo-top);
  width: var(--logo-width);

  @include focus-visible {
    outline: 3px solid white;
    outline-offset: var(--pbsk-spacing-2);
  }
}

.whoopsText {
  grid-area: whoopstext;
  margin-bottom: var(--pbsk-spacing-8);
  text-align: center;
  font-size: 100%;

  @include breakpoint(md) {
    display: grid;
    align-items: end;
    grid-template-rows: auto max-content;
    max-width: var(--boxes-width);
    text-align: left;
    margin-bottom: var(--pbsk-spacing-2);
  }
}

.whoopsTextBig {
  display: block;
  margin-bottom: var(--pbsk-spacing-3);
  font-family: var(--pbsk-font-family-headline);
  font-size: var(--big-text-size);
  color: var(--pbsk-color-black);
}

.whoopsTextSmall {
  font-size: var(--small-text-size);
  font-family: var(--pbsk-font-family-sans);
  color: var(--pbsk-color-black);

  @include breakpoint(md) {
    line-height: 1.75;
  }
}

.boxButton {
  a {
    border: 2px solid #FFF;
    display: block;
    outline: none;

    @include focus-visible {
      outline: 3px solid var(--pbsk-color-white);
      outline-offset: var(--pbsk-spacing-2);
    }
  }

  img {
    width: 100%;
    display: block;
  }
}

.pbsKidsHomeBox {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fee000;

    @include aspect-ratio(236, 133);
  }

  svg {
    width: 40%;
    height: auto;
  }
}

.boxes {
  grid-area: boxes;
  display: grid;
  grid-gap: var(--pbsk-spacing-4);
  align-items: center;
  justify-content: center;
  width: var(--boxes-width);
  margin: 0 auto 1em;

  @include breakpoint(sm) {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0 2em;
  }
}

.fallingKidsbox {
  position: relative;
  grid-area: fallingkids;
  height: var(--falling-kids-height);

  div {
    position: absolute;
    height: var(--falling-kids-height);
    background-size: contain;
  }
}

.kidOne {
  width: var(--kid-one-width);
  background: url('/puma/images/error-pages/kid1.svg') no-repeat 50% 100%;
  margin: 0 0 0 var(--kid-one-left);
}

.kidTwo {
  width: var(--kid-two-width);
  background: url('/puma/images/error-pages/kid2.svg') no-repeat 50% 77%;
  margin: 0 0 0 var(--kid-two-left);
}

.kidThree {
  width: var(--kid-three-width);
  background: url('/puma/images/error-pages/kid3.svg') no-repeat 50% 5px;
  margin: 0 0 0 var(--kid-three-left);
}

.slideDown {
  animation-name: slideDown;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

.slideDown2 {
  animation-delay: 0.5s;
  animation-duration: 3s;
  animation-fill-mode: backwards;
  animation-name: slideDown;
  animation-timing-function: ease-in-out;
}

.slideDown3 {
  animation-delay: 1s;
  animation-duration: 3s;
  animation-fill-mode: backwards;
  animation-name: slideDown;
  animation-timing-function: ease-in-out;
}

@keyframes slideDown {
  0% {
    transform: translateY(-200%);
  }

  50% {
    transform: translateY(8%);
  }

  65% {
    transform: translateY(-4%);
  }

  80% {
    transform: translateY(4%);
  }

  95% {
    transform: translateY(-2%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown2 {
  0% {
    transform: translateY(-200%);
  }

  50% {
    transform: translateY(8%);
  }

  65% {
    transform: translateY(-4%);
  }

  80% {
    transform: translateY(4%);
  }

  95% {
    transform: translateY(-0.1%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown2 {
  0% {
    transform: translateY(-200%);
  }

  50% {
    transform: translateY(8%);
  }

  65% {
    transform: translateY(-4%);
  }

  80% {
    transform: translateY(4%);
  }

  95% {
    transform: translateY(-0.1%);
  }

  100% {
    transform: translateY(0%);
  }
}
