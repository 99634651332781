// Breakpoints

// Usage:
// @include breakpoint(md) { ...stuff }
// or, for custom media query values:
// @include breakpoint('screen and (min-width: 1000px)') { ...stuff }

@import '@/styles/variables/breakpoints';

@mixin breakpoint($query) {
  @if $query == xs {
    @media only screen and (min-width: #{$breakpoint-xs}) { @content; }
  } @else if $query == sm {
    @media only screen and (min-width: #{$breakpoint-sm}) { @content; }
  } @else if $query == md {
    @media only screen and (min-width: #{$breakpoint-md}) { @content; }
  } @else if $query == lg {
    @media only screen and (min-width: #{$breakpoint-lg}) { @content; }
  } @else if $query == xl {
    @media only screen and (min-width: #{$breakpoint-xl}) { @content; }
  } @else if $query == xxl {
    @media only screen and (min-width: #{$breakpoint-xxl}) { @content; }
  } @else if type-of($query == string) {
    @media #{$query} { @content; }
  } @else {
    @warn 'Breakpoint mixin only supports xs, sm, md, lg, xl, and xxl OR a custom string value.';
  }
}

@function breakpoint-name-to-number($name) {
  @if $name == xs { @return $breakpoint-xs; }

  @if $name == sm { @return $breakpoint-sm; }

  @if $name == md { @return $breakpoint-md; }

  @if $name == lg { @return $breakpoint-lg; }

  @if $name == xl { @return $breakpoint-xl; }

  @if $name == xxl { @return $breakpoint-xxl; }

  @warn 'Invalid breakpoint name: #{$name}';
  @return null;
}

@mixin between-breakpoints($min-name, $max-name: null) {
  $min-value: breakpoint-name-to-number($min-name);
  $max-value: breakpoint-name-to-number($max-name);

  @if not $max-name {
    @error 'You didn\'t supply a max-name. Use mixin \'breakpoint\' instead.';
  } @else if $min-value == null {
    @media only screen and (max-width: #{$max-value - 1}) {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$min-value}) and (max-width: #{$max-value - 1}) {
      @content;
    }
  }
}
